import { createAction } from 'redux-actions';
import * as apiClient from '../../services';

export enum ACTIONS {
  GET_BY_TOBE_PAY = 'PO/GET_BY_TOBE_PAY',
  GET_BY_HISTORY = 'PO/GET_BY_HISTORY',
  SELECT_ORDER_TOBE_PAY = 'PO/SELECT_ORDER_TOBE_PAY',
  UNSELECT_ALL = 'PO/UNSELECT_ALL',
  GET_BY_PAYMENTS_IN_PROGRESS = 'PO/GET_BY_PAYMENTS_IN_PROGRESS',
  DISPUTE_TRANSACTION_PAYMENT = 'DISPUTE_TRANSACTION_PAYMENT',
}

export const getByToBePay = createAction(ACTIONS.GET_BY_TOBE_PAY, apiClient.order.getByToBePay);
export const getByHistory = createAction(ACTIONS.GET_BY_HISTORY, apiClient.order.getByHistory);
export const getByPaymentsInProgress = createAction(ACTIONS.GET_BY_PAYMENTS_IN_PROGRESS, apiClient.order.getByPaymentsInProgress);
export const selectOrdersToBePay = createAction(ACTIONS.SELECT_ORDER_TOBE_PAY);
export const unselectAll = createAction(ACTIONS.UNSELECT_ALL);
export const setDisputeTransactionPayment = createAction(ACTIONS.DISPUTE_TRANSACTION_PAYMENT, apiClient.order.setDisputeTransactionPayment);
