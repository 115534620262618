import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import dotenv from 'dotenv';
import reportWebVitals from './reportWebVitals';
import { store } from '@redux';
import AppRouter from 'src/site/routes';
import { useEffect } from 'react';
import { initializeTracker } from '@common-utils/metrics';
import { ApolloProvider } from '@apollo/client';
import { getGraphqlClient } from '@common-services/graphql';

import './styles/global.scss';

dotenv.config();

const apolloClient = getGraphqlClient();

const App = () => {

  useEffect(() => {
    initializeTracker();
    console.log("tracker")
  }, []);

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <AppRouter />
      </ApolloProvider>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();