import { gql } from '@apollo/client';

export const GET_MERCHANT = gql`
  query merchantByReference($reference: String!) {
    merchant(reference: $reference){
      id
      businessName
      reference
      onboardingLink {
          link
          kaltoLink
      }
    }
  }`;