import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { kaltoEndpoints } from './endpoints';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: kaltoEndpoints.MIDDLEWARE,
});

const publicHttpLink = createHttpLink({
    uri: kaltoEndpoints.OPEN_MIDDLEWARE,
});

const tokenHeader = process.env.REACT_APP_KALTO_GRAPHQL_TOKEN || '';

const withAuth = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            "x-api-key": tokenHeader,
            'x-merchant-key': 'AdxLxpBvLM6434YtDmScuixQZdq7EW5c'
        }
    }
});

export const getGraphqlClient = (isPublic: boolean = false) => {
    const client = new ApolloClient({
        link: isPublic ? withAuth.concat(publicHttpLink) : withAuth.concat(httpLink),
        cache: new InMemoryCache()
    });
    return client;
};
