import { gql } from '@apollo/client';

export const GET_PO_BY_TOBE_PAY = gql`
  query ClientPortalTransactionsToBePaid($filters: ClientPortalTransactionFilters!) {
    clientPortalTransactionsToBePaid(filters: $filters) {
      list {
        reference
        clientReference
        sequenceNumber
        dueDate
        dateFrom
        expired
        pendingAmount
        currency        
        paymentOrder {
          currencyCode
          totalAmount
          status
          reference
          created
          issuedAt
          hasReceipt
          paymentType
          merchantReference
          installment {
            count
          }
          notes
          fiscalNumber
        }
        owedToMerchant
        details {
          name
          type
          detailId
          totalAmount
          paidAmount
         }
         allowsPaymentDispute
      }
      size
      totalPages
      totalElements
      number
    }
  }
`;

export const GET_PO_BY_HISTORY = gql`
query ClientPortalTransactionsHistory($filters: ClientPortalTransactionFilters!) {
  clientPortalTransactionsHistory(filters: $filters) {
    list {
      reference
      clientReference
      sequenceNumber
      dueDate
      dateFrom
      expired
      pendingAmount        
      totalAmount
      status
      updated
      paidAmount
      paymentOrder {
        reference
        created
        status
        paymentType
        hasReceipt
        merchantReference
        installment {
          count
        }
      }
      details {
        name
        type
        detailId
        totalAmount
        paidAmount
      }
      lastPayment {
        paymentDate
        amount
        paymentMethod
        reference
      }
    }
    size
    totalPages
    totalElements
    number
  }
}
`;

export const GET_PO_BY_PAYMENTS_IN_PROGRESS = gql`
query ClientPortalPaymentsInProgress($filters: ClientPortalOngoingPaymentsFiltersInput!) {
  clientPortalOngoingPayments(filters: $filters) {
    list {
      reference
      operationReference
      paymentDate
      amount
      paymentMethod
      paymentType
      allowsConciliation
    }
    size
    totalPages
    totalElements
    number
  }
}
`;

export const DISPUTE_TRANSACTION_PAYMENT = gql`
  mutation ($disputeTransactionPaymentInput: DisputeTransactionPaymentInput!) {
    disputeTransactionPayment(disputeTransactionPaymentInput: $disputeTransactionPaymentInput) {
      id
    }
  }
`;