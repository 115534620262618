import { getGraphqlClient } from '@graphql';
import { CLIENT_INSIGHTS_EXPIRED_PAYMENTS, CLIENT_INSIGHTS_PENDING_PAYMENTS, CLIENT_INSIGHTS_UPCOMING_PAYMENTS } from "./queries";
import { mapperExpiredPayments, mapperPendingPayments, mapperUpcomingPayments } from './mapper';
import { IClient } from 'src/common/types';

export const getExpiredCardDataByClient = async (clientReference: string) => {
    const client = getGraphqlClient();
    const query = CLIENT_INSIGHTS_EXPIRED_PAYMENTS;
    try {
        return await client
            .query({
                query,
                variables: { clientReference },
            })
            .then((res) => mapperExpiredPayments(res.data.clientInsightsExpiredPayments));
    } catch (err) {
        return err;
    }
};

export const getPendingCardDataByClient = async (clientReference: string) => {
    const client = getGraphqlClient();
    const query = CLIENT_INSIGHTS_PENDING_PAYMENTS;
    try {
        return await client
            .query({
                query,
                variables: { clientReference },
            })
            .then((res) => mapperPendingPayments(res.data.clientInsightsPendingPayments));
    } catch (err) {
        return err;
    }
};

export const getUpcomingPaymentsCardDataByClient = async (clientReference: string) => {
    const client = getGraphqlClient();
    const query = CLIENT_INSIGHTS_UPCOMING_PAYMENTS;
    try {
        return await client
            .query({
                query,
                variables: { clientReference },
            })
            .then((res) => mapperUpcomingPayments(res.data.clientInsightsUpcomingPayments));
    } catch (err) {
        return err;
    }
};

export const getCreditLineCardDataByClient = (client: IClient, showCard: boolean) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                creditLineAmount: client?.creditLine?.balance.availableAmount,
                totalCredit: client?.creditLine?.amount,
                isCreditPaused: false,
                status: client?.termsQualificationStatus,
                showCard
            });
        }, 100);
    });
};