import { TypeFormat } from "src/common/types";
import { IPOGrid } from "../types/IPOGrid";

const POGrid: IPOGrid = {
    installment: (step: number, total: number) => `Cuota ${step} de ${total}`,
    orderStatus: {
        DRAFT: 'Borrador',
        CREATED: 'Emisión',
        SUBMITTED: 'Pendiente',
        COMPLETED: 'Pagada',
        CANCELLED: 'Cancelada',
        EXPIRED: 'Vencida',
        PARTIALLY_PAID: 'Pago parcial',
        PAID: 'Pagada'
    },
    orderRow: {
        downloadComplements: 'Descargar complemento(s) de pago',
        downloadReceiptPdf: 'Descargar PDF',
        downloadReceiptXml: 'Descargar XML',
        errorDownloadingComplement: 'Error al intentar descargar los complementos',
        complementNotFound: 'La orden no contiene complementos para descargar',
        downloadReceiptError: (typeFormat: TypeFormat) => `Ocurrió un problema al descargar el ${typeFormat}. Intente nuevamente.`
    },
    paymentMethods: {
        CREDIT_CARD: 'Tarjeta de crédito',
        BANK_TRANSFER: 'Transferencia Bancaria',
        CASH: 'Efectivo',
        CREDIT: 'Crédito',
        BANK_DEPOSIT: 'Depósito bancario'
    },
    dueDate: (expired, diffDay) => {
        if (diffDay === 0) return `Vence hoy`;
        const sinceLabel = expired ? 'Hace' : 'Vence en';
        const daysLabel = diffDay === 1 ? 'día' : 'días';
        return `${sinceLabel} ${diffDay} ${daysLabel}`
    },
    nav: {
        paid: 'Pagadas',
        toBePaid: 'Por pagar',
        paymentsInProgress: 'Pagos en curso',
    },
    postponedTagTitle: 'Aplazada',
    unselectAll: 'Deseleccionar todas',
    selectedOf: 'seleccionados de',
    folio: ' - Folio ',
    assignInvoicesLabel: 'Asignar facturas',
    reportPayment: 'Informar pago',
    paymentDate: 'Fecha de pago',
    paymentType: {
        inReview: 'En revisión',
        withoutInvoices: 'Sin facturas',
    },
    modalPaymentDispute: {
        modalTitle: '¡Orden en revisión!',
        modalParagraph: 'Echaremos un vistazo y te enviaremos un correo con actualizaciones.',
        modalBtnClose: 'Cerrar',
    }
};

export default POGrid;