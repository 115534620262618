import { ACTION_SUFFIX } from '@redux/interfaces';
import { ACTIONS } from '../actions';

export const GET_MERCHANT_REFERENCE =
{
    [`${ACTIONS.GET_MERCHANT_REFERENCE}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
        ...state,
        isLoading: true,
        error: null,
        client: null,
    }),
    [`${ACTIONS.GET_MERCHANT_REFERENCE}${ACTION_SUFFIX._FULFILLED}`]: (state: any, action: { payload }) => {
        const hasError = (!!action.payload && !!action.payload.graphQLErrors)
        if (!!hasError)
            return {
                ...state,
                isLoading: false,
                data: null,
                error: action.payload,
            };

        return {
            ...state,
            isLoading: false,
            data: action.payload,
            error: null
        };
    },
    [`${ACTIONS.GET_MERCHANT_REFERENCE}${ACTION_SUFFIX._REJECTED}`]: (state, action: { payload: any }) => ({
        ...state,
        isLoading: false,
        data: null,
        error: action.payload
    })
};

export const SET_MERCHANT_REFERENCE = {
    [ACTIONS.SET_MERCHANT_REFERENCE]: (
        state,
        action: { payload }
    ) => {
        const { reference } = action.payload;
        return {
            ...state,
            merchantReference: reference
        }
    }
}