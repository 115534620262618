import { mapperGetByToBePay, mapperGetHistory, mapperPaymentInProgress } from './mapper';
import { GET_PO_BY_TOBE_PAY, GET_PO_BY_HISTORY, GET_PO_BY_PAYMENTS_IN_PROGRESS, DISPUTE_TRANSACTION_PAYMENT } from './graphql';
import { getGraphqlClient } from '@graphql';
import { ClientPortalTransactionFilters, DisputeTransactionPaymentInput } from '../../types';

export const getByToBePay = async (params: ClientPortalTransactionFilters) => {
  const client = getGraphqlClient();
  const query = GET_PO_BY_TOBE_PAY;

  try {
    return await client
      .query({
        query,
        variables: { filters: { ...params } },
      })
      .then((res) => {
        return mapperGetByToBePay(res);
      });
  } catch (err) {
    return err;
  }
}

export const getByHistory = async (filters: ClientPortalTransactionFilters) => {
  const client = getGraphqlClient();
  const query = GET_PO_BY_HISTORY;
  try {
    return await client
      .query({
        query,
        variables: { filters },
      })
      .then((res) => {
        return mapperGetHistory(res);
      });
  } catch (err) {
    return err;
  }
};

export const getByPaymentsInProgress = async (filters: ClientPortalTransactionFilters) => {
  const client = getGraphqlClient();
  const query = GET_PO_BY_PAYMENTS_IN_PROGRESS;
  try {
    return await client
      .query({
        query,
        variables: { filters },
      })
      .then((res) => {
        return mapperPaymentInProgress(res);
      });
  } catch (err) {
    return err;
  }
};

export const setDisputeTransactionPayment = (disputeTransactionPaymentInput: DisputeTransactionPaymentInput) => {
  const graphqlClient = getGraphqlClient();
  const mutation = DISPUTE_TRANSACTION_PAYMENT;

  return graphqlClient
    .mutate({
      mutation,
      variables: { disputeTransactionPaymentInput }
    })
    .then((res) => {
      return res?.data?.disputeTransactionPayment;
    }).catch((err) => {
      return err;
    }
    );
}