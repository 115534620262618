import { IPaymentSummary } from "../types/IPaymentSummary";

const PaymentSummary: IPaymentSummary = {
    title: 'Resumen de compra',
    dueDate: 'Vencimiento',
    subTotal: 'Subtotal',
    total: 'Total',
    payButton: 'Realizar pago',
    ordersAmount: 'Órdenes de pago',
    ivaTax: (taxAmount: number) => `IVA ${taxAmount}%`,
    lateFee: 'Comisión por atraso',
    totalOrders: 'Órdenes totales'
};

export default PaymentSummary;