
const ErrorService = {
    UNEXPECTED_ERROR: 'No se ha podido procesar tu pago, inténtalo de nuevo. Si el error continua por favor contáctanos',
    PROCESSOR_UNEXPECTED_ERROR: 'No hemos podido procesar el pago con tu tarjeta, por favor utiliza un medio de pago diferente o contáctanos para revisar tu caso.',
    CARD_NOT_ENABLED_BY_ISSUER: 'Esta tarjeta de crédito no se puede utilizar, por favor contacta a tu banco',
    REPORTED_CARD: 'Esta tarjeta de crédito no se puede utilizar, por favor utiliza una diferente',
    EXPIRED_CARD: 'Esta tarjeta de crédito no se puede utilizar, por favor utiliza una diferente',
    CARD_REPORTED_AS_LOST: 'Esta tarjeta de crédito no se puede utilizar, por favor utiliza una diferente',
    CARD_REPORTED_AS_STOLEN: 'Esta tarjeta de crédito no se puede utilizar, por favor utiliza una diferente',
    CARD_TYPE_NOT_ALLOWED: 'Esta tarjeta de crédito no se puede utilizar, por favor utiliza una diferente',
    INVALID_CVV_NUMBER: 'El código de seguridad es incorrecto',
    INVALID_CARD_NUMBER: 'El número de la tarjeta es incorrecto',
    INVALID_CARD_EXPIRATION_DATE: 'El fecha de vencimiento es incorrecta',
    INVALID_CARDHOLDER_FIRSTNAME: 'El nombre que figura en la tarjeta es incorrecto',
    INVALID_CARDHOLDER_LASTNAME: 'El nombre que figura en la tarjeta es incorrecto',
    PROCESSOR_UNABLE_TO_PROCESS_TRANSACTION: 'No hemos podido procesar el pago con tu tarjeta, por favor utiliza un medio de pago diferente o contáctanos para revisar tu caso.',
    CALL_BANK_TO_AUTHORIZE: 'Esta transacción ha sido rechazada, por favor contacta a tu banco',
    TRANSACTION_DECLINED: 'Su pago ha sido rechazado, por favor utilice otra tarjeta o contáctenos para revisar su caso.',
    TRANSACTION_NOT_ALLOWED: 'La transacción no se ha podido realizar, por favor utiliza una tarjeta diferente',
    REJECTED_FOR_FRAUD: 'No hemos podido procesar el pago con tu tarjeta, por favor utiliza un medio de pago diferente o contáctanos para revisar tu caso.',
    INSUFFICIENT_FUNDS: 'La tarjeta no cuenta con los fondos suficientes para pagar',
    UNREACHABLE_PROCESSOR: 'No hemos podido procesar el pago con tu tarjeta, por favor utiliza un medio de pago diferente o contáctanos para revisar tu caso.',
    CARD_ISSUER_UNABLE_TO_AUTHORIZE: 'Esta tarjeta no se puede utilizar, por favor utiliza una diferente',
    METHOD_NOT_ALLOWED: 'No hemos podido procesar tu pago, no te preocupes no se realizó ninguna transacción. Por favor intenta nuevamente.',
    CHARGE_RETRIES_LIMIT_EXCEEDED: 'No se ha podido procesar tu pago, inténtalo de nuevo. Si el error continua por favor contáctanos',
    DAILY_TRANSACTION_LIMIT_EXCEEDED: 'Esta tarjeta ha superado el limite de transacciones diario, por favor utilice otra tarjeta o intente nuevamente en 24 horas.'
}

export default ErrorService