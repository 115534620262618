import { IApprovalPortal } from "src/language/types/approvalPortal/IApprovalPortal";

const ApprovalPortal: IApprovalPortal = {
    dontHaveAccountLabel: '¿No tienes cuenta?',
    invoicesSuccessfullyApproved: {
        primaryBtnLabel: 'Entendido',
        subtitle: 'Enviamos una notificación al propietario para autorizar las facturas. Una vez se complete este proceso, el administrador podrá realizar el pago.',
        title: '¡Facturas aprobadas con éxito!',
    },
    linkPrivacyPolicy: 'https://term-conditions.kalto.la/AvisoPrivacidad.pdf',
    linkTermsAndConditions: 'https://term-conditions.kalto.la/TCs.pdf',
    msgError: 'Ocurrió un error. Intenta de nuevo.',
    privacyPolicy: "Aviso de privacidad",
    signUpBtnLabel: 'Registrarse',
    termsAndConditions: "Términos y condiciones",
};

export default ApprovalPortal;