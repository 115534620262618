
import TagManager from 'react-gtm-module'
import { TRACK } from './tracks';
const GTM_ID = process.env.REACT_APP_KALTO_GTM_ID || null;
const GTM_AUTH = process.env.REACT_APP_KALTO_GTM_AUTH;
const GTM_ENV = process.env.REACT_APP_KALTO_GTM_ENV;

export const initializeTracker = (data?: Object) => {
    if (!GTM_ID) return;
    const tagManagerArgs = {
        gtmId: GTM_ID,
        auth: GTM_AUTH,
        preview: GTM_ENV,
        dataLayer: {
            event: TRACK.LOADED,
            ...data
        }
    }
    TagManager.initialize(tagManagerArgs)
}

export const trackEvent = (event: TRACK, data?: Object) => {
    if (!GTM_ID) return;
    TagManager.dataLayer({
        dataLayer: {
            event,
            ...data
        }
    });
}

export { TRACK };