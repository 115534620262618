import Amount from "src/common/components/amount"
import { IInstallmentLang } from "src/language/types/payment-order-info/IInstallmentLang"

const InstallmentsLang: IInstallmentLang = {
    title: 'Información de cuotas',
    installment: 'Cuota',
    detail: (date: string, receivedAmount?: number) => (
        <>
            {
                receivedAmount ?
                    <>
                        Monto Recibido <Amount value={receivedAmount}></Amount> el {date}
                    </>
                    :
                    <> Vence el {date} </>

            }
        </>
    )
}

export default InstallmentsLang;