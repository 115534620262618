import { ClientPortalTransactionPage } from "../../types";

export const mapperGetByToBePay = ({ data }): ClientPortalTransactionPage => {
  return data.clientPortalTransactionsToBePaid;
}

export const mapperGetHistory = ({ data }): ClientPortalTransactionPage => {
  return data.clientPortalTransactionsHistory;
}

export const mapperPaymentInProgress = ({ data }): ClientPortalTransactionPage => {
  return data.clientPortalOngoingPayments;
}