import { IPaymentOrderStatusBadgeLang } from "src/language/types/payment-by-order/IPaymentOrderStatusBadgeLang";

const PaymentOrderStatusBadgeLang: IPaymentOrderStatusBadgeLang = {
  text: {
    expires: 'Vence el',
    expiredOn: 'Vencida el',
    paidOn: 'Pagada el',
    canceledOn: 'Cancelada el',
    authorized: 'Autorizada el'
  },
}

export default PaymentOrderStatusBadgeLang;