import { createCheckoutID } from "./checkout";
import { clientByPaymentOrderReference, clientByReference, persistClient, clientByFilters, getJourney, createJourney,downloadClientBalance } from "./client";
import { merchantByReference } from "./merchant";
import { downloadPaymentOrderInvoice } from "./paymentOrders";

export const client = {
    byPaymentOrderReference: clientByPaymentOrderReference,
    byReference: clientByReference,
    persistClient,
    byFilters: clientByFilters,
    getJourney: getJourney,
    createJourney,
    downloadClientBalance
}

export const checkout = {
    createCheckoutID
}

export const merchant = {
    byReference: merchantByReference
}

export const paymentOrders = {
    downloadInvoice: downloadPaymentOrderInvoice
}