import { ICreditCardAuthorization } from "../types/ICreditCardAuthorization";

const CreditCardAuthorization: ICreditCardAuthorization = {
    cardSuccess: {
        title: '¡Pago exitoso!',
        paragraphHeader: (totalAmount) => (
            <>
                El pago por{' '}${totalAmount}{' '}ha sido recibido.
            </>
        ),
    },
    cardDeclined: {
        title: '¡Pago rechazado!',
        paragraphHeader: (totalAmount) => (
            <>
                El pago por{' '}${totalAmount}{' '}ha sido rechazado.
            </>
        ),
    },
}

export default CreditCardAuthorization;