import { gql } from '@apollo/client';

const CLIENT_QUERY = `
  id
  businessName
  commercialBusinessName
  termsQualificationStatus
  reference
  email
  taxId
  sellerBusinessReference
  merchant {
    id
    businessName
    email
    reference
    onboardingLink {
      link
      kaltoLink
    }
  }
  creditLine {
    id
    amount
    currency
    status
    balance {
      availableAmount
      overdueAmount
    }
  }
  onboardingLink {
    link
    kaltoLink
  }
  paymentMethods {
    enabled
    paymentMethod {
      name
    }
  }
`;

export const GET_CLIENT_UID = gql`
  query clientByReference($reference: String!) {
    clientByReference(reference: $reference) {
      ${CLIENT_QUERY}
    }
  }`;

export const GET_CLIENT_BY_PO = gql`
  query clientByPaymentOrderReference($paymentOrderReference: String!) {
    clientByPaymentOrderReference(paymentOrderReference: $paymentOrderReference) {
      ${CLIENT_QUERY}
    }
  }`;

export const PERSIST_CLIENT = gql`
mutation ($clientInput: ClientInput) {
  persistClient(clientInput: $clientInput) {
    ${CLIENT_QUERY}
  }
}
`;

export const GET_CLIENT_BY_FILTERS = gql`
query ($merchantId: ID!, $filters: ClientFilters, $page: Int!, $size: Int!) {
  clients(merchantId: $merchantId, clientFilters: $filters, page: $page, size: $size) {
      list {
          ${CLIENT_QUERY}
      }
      size
      totalPages
      totalElements
      number
    }
  }`;

export const GET_JOURNEY = gql`
  query ($taxId: String!, $merchantReference: String!) {
    onboardingJourney(taxId: $taxId, merchantReference: $merchantReference) {
      onboardingLink
      onboardingLinks
      status
      gatewayName
    }
  }`;

export const CREATE_JOURNEY = gql`
mutation ($clientReference: String!) {
  createOnboarding(clientReference: $clientReference) {
    onboardingLink
    onboardingLinks
    status
    gatewayName
  }
}`;

export const DOWNLOAD_BALANCE = gql`
mutation ($clientReference: String!) {
  accountStatementPDF(clientReference: $clientReference)
}`;