import { ACTION_SUFFIX } from '@redux/interfaces';
import { ClientPortalTransaction, ClientPortalTransactionPage } from 'src/modules/clientPortal/types';
import { ACTIONS } from "../actions";

const getExpired = (data: Array<ClientPortalTransaction>) => {
    return data.filter((order: ClientPortalTransaction) => (!!order.expired));
}

const getTotalAmount = (ordersSelected: Array<ClientPortalTransaction>): number =>
    ordersSelected.reduce((acc, order: ClientPortalTransaction) => {
        return acc + order.pendingAmount;
    }, 0);

export const GET_BY_TOBE_PAY = {
    [`${ACTIONS.GET_BY_TOBE_PAY}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
        ...state,
        toBePay: {
            ...state.toBePay,
            isLoading: true,
            error: null,
            data: null,
        }
    }),
    [`${ACTIONS.GET_BY_TOBE_PAY}${ACTION_SUFFIX._FULFILLED}`]:
        (state: any, action: { payload: Array<ClientPortalTransaction> | any }) => {
            if (!!action.payload.graphQLErrors)
                return getByTobePayError(state, action.payload);

            return getByTobePayFullfilled(state, action.payload);
        },
    [`${ACTIONS.GET_BY_TOBE_PAY}${ACTION_SUFFIX._REJECTED}`]: (state, action: { payload: any }) => ({
        ...state,
        toBePay: {
            ...state.toBePay,
            isLoading: false,
            error: action.payload,
            data: null,
            selected: null,
            totalAmount: null
        }
    })
}

const getSelectedUnique = (state, data: ClientPortalTransaction[]) => {
    let selected = getExpired(data);
    if (data.length > 0 && selected.length === 0) {
        const nextToExpire = data[0];
        return [nextToExpire];
    }
    if (!!state.toBePay?.selected?.length)
        selected = [...selected, ...state.toBePay.selected.filter(inState => (
            !selected.some(expired => (expired.reference === inState.reference)))
        )];
    return selected;
}

const getByTobePayFullfilled = (state, payload: ClientPortalTransactionPage) => {
    const data = payload.list || [];
    const selected = getSelectedUnique(state, data);

    return {
        ...state,
        toBePay: {
            ...state.toBePay,
            isLoading: false,
            error: null,
            data: payload,
            selected,
            totalAmount: getTotalAmount(selected)
        }
    };
}

const getByTobePayError = (state, payload: Array<ClientPortalTransaction>) => {
    return {
        ...state,
        toBePay: {
            ...state.toBePay,
            isLoading: false,
            data: null,
            error: payload,
            selected: null,
            totalAmount: null
        }
    }
}

const addSelected = (state, reference) => {
    return [...state.toBePay.selected, state.toBePay.data.list.find(item => item.reference === reference)]
}

const removeSelected = (state, reference) => {
    return state.toBePay.selected.filter(item => item.reference !== reference)
}

export const SELECT_ORDER_TOBE_PAY = {
    [`${ACTIONS.SELECT_ORDER_TOBE_PAY}`]: (state, action: { payload: { reference: string, isSelected: boolean } }) => {

        const { reference, isSelected } = action.payload;
        let ordersSelected = (isSelected) ? addSelected(state, reference) : removeSelected(state, reference);

        return {
            ...state,
            toBePay: {
                ...state.toBePay,
                selected: ordersSelected,
                totalAmount: getTotalAmount(ordersSelected)
            }
        }
    }
}

export const UNSELECT_ALL = {
    [`${ACTIONS.UNSELECT_ALL}`]: (state) => {
        return {
            ...state,
            toBePay: {
                ...state.toBePay,
                selected: [],
                totalAmount: 0
            }
        }
    }
}