import { getGraphqlClient } from '@graphql';
import { CREATE_CHECKOUT_ID } from './graphql';

export const createCheckoutID = (transactionReferences: Array<string>) => {
  const graphqlClient = getGraphqlClient();
  const mutation = CREATE_CHECKOUT_ID;

  const variables = {
    CheckoutHashInput: {
      transactionReferences
    }
  };

  return graphqlClient
    .mutate({
      mutation,
      variables
    })
    .then((res) => {
      return res.data?.createCheckoutId.id;
    }).catch((err) => {
      return err;
    }
    );
}
