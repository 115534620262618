import { IPaymentByOrderLang } from "src/language/types/payment-by-order/IPaymentByOrderLang";

const PaymentByOrderLang: IPaymentByOrderLang = {
    returnTo: "Volver al comercio",
    helloBuyer: "Hola",
    mainTitle: (
        <>
          <span className="fw-bold">¿cómo quieres</span> pagar tu orden? 👇
        </>
      ),
    details: {
      title: "Detalles",
      buttonTitle: "Ver más órdenes",
      issueDate: "Fecha de emisión",
      expirationDate: "Fecha de vencimiento",
      orderType: "Tipo de orden",
      issuerBusinessName: "Razón social emisor",
      receiverBusinessName: "Razón social receptor",
    },
    summary: "Resumen",
    subtotal: "Subtotal",
    totalOrder: "Orden total",
    disclaimer: "Aviso de responsabilidad",
    modalFirstParagraph: "“Kalto Tech, S.A. de C.V. realiza el servicio de cobro de cuentas por cobrar de sus comitentes, por cuenta y orden de estos. Por lo que, Kalto utiliza a terceros prestadores de servicios y procesadores de diversos métodos de pago. El pago de cuentas por pagar por parte de Usted (Pagador) no genera relación jurídica alguna con Kalto.",
    modalSecondParagraph: "Kalto recibe los importes pagados por Usted al comitente de Kalto a nombre y por cuenta de éste, para cancelación de las obligaciones que Usted tiene con dicho comitente”",
    buttonClose: "Cerrar y continuar",
    responsiveHeader: {
      youWillPay: "Vas a pagar",
      seeMoreDetails: "Ver más detalles",
    },
    pOPaid: {
      title: "Ya pagaste esta orden.",
      firstParagraph: "Podrás encontrarla en la pestaña de historial",
      secondParagraph: "en tu portal de clientes.",
      button: "Ir al portal",
    },
    pOCancelled: {
      title: "Esta orden está cancelada.",
      firstParagraph: "Podrás encontrarla en la pestaña de",
      secondParagraph: "canceladas en tu dashboard.",
    },
    paymentInvoice: 'Factura emitida'
}

export default PaymentByOrderLang;