import { handleActions } from 'redux-actions';
import { ACTION_SUFFIX } from '@redux/interfaces';
import { ACTIONS } from './actions';

interface IState {
  isLoading: boolean;
  error: any;
  checkoutID: string;
}

const checkoutState: IState = {
  isLoading: true,
  error: null,
  checkoutID: null
};

export type TCheckoutState = typeof checkoutState;

export default handleActions(
  {
    [`${ACTIONS.CREATE_CHECKOUT_ID}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
      ...state,
      isLoading: true,
      error: null,
      checkoutID: null,
    }),
    [`${ACTIONS.CREATE_CHECKOUT_ID}${ACTION_SUFFIX._FULFILLED}`]: (state: any, action: { payload }) => {
      if (!!action.payload.graphQLErrors)
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      return {
        ...state,
        isLoading: false,
        checkoutID: action.payload
      };
    },
    [`${ACTIONS.CREATE_CHECKOUT_ID}${ACTION_SUFFIX._REJECTED}`]: (state, action: { payload: any }) => ({
      ...state,
      isLoading: false,
      error: action.payload
    }),
    [`${ACTIONS.CLEAR_CHECKOUT_ID}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
      ...state,
      isLoading: false,
      error: null,
      checkoutID: null
    }),
  },
  checkoutState
);
