export const ENV_ARRAY_SEPARATOR = ',';

export enum EINVOICE_STATUS {
  PAID = 'PAID',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  COMPLETED = 'COMPLETED'
}
export const PAYMENT_MODULE_EVENTS = {
  closed_modal: 'kalto-pay-closed',
  emit_payment_approved: 'kalto-pay-payment-approved',
  emit_payment_pending: 'kalto-pay-payment-pending'
}
export enum PAYMENT_MODULE_STATUS {
  PENDING = 'PENDING',
  PAID = 'PAID',
  ABORTED = 'ABORTED'
}
export enum CREDIT_LINE_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum MESSAGE_TYPE {
  SUCCESS = 'success',
  ERROR = 'danger',
  WARNING = 'warning',
  INFO = 'info'
}

export const PRICE_SYMBOL = '$';

export enum CURRENCIES {
  MXN = 'MXN',
  USD = 'USD',
};

export enum ORDER_PAYMENT_TYPE {
  PPD = "PPD",
  PUE = "PUE",
}

export const DEFAULT_SIZE_PAGE = 20;

export const KEY_SUMMARY_CARD = {
  EXPIRED: 'EXPIRED',
  PENDING: 'PENDING',
  UPCOMING: 'UPCOMING',
  CREDIT_LINE: 'CREDIT_LINE'
};

export const PAYMENT_METHODS = {
  BANK_TRANSFER: { code: 'BANK_TRANSFER', iconClass: 'bank-transfer' },
  CASH: { code: 'CASH', iconClass: 'cash' },
  CREDIT: { code: 'CREDIT', iconClass: 'credit' },
  DIRECT_DEBIT: { code: 'DIRECT_DEBIT', iconClass: 'direct-debit' },
  CREDIT_CARD: { code: 'CREDIT_CARD', iconClass: 'credit-card-1' },
  BANK_DEPOSIT: { code: 'BANK_DEPOSIT', iconClass: 'bank-deposit' }
}

export enum PAYMENT_ORDER_TYPE {
  INVOICE = "INVOICE",
  INSTALLMENT = "INSTALLMENT",
}

export enum TRANSACTION_DETAIL_TYPE {
  LATE_FEE = 'LATE_FEE',
  DEBT = 'DEBT',
}

export enum PAYMENT_TYPE {
  UNRECONCILED_PAYMENT = "UNRECONCILED_PAYMENT",
  DISPUTED_PAYMENT = "DISPUTED_PAYMENT",
}
