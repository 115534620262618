import { handleActions } from 'redux-actions';
import { ACTION_SUFFIX } from '@redux/interfaces';
import { ACTIONS } from './actions';
import { IClient } from 'src/common/types';
import { ILastPayment, INextTransaction } from 'src/PO-commons/types';

interface IPaymentByOrderState {
  paymentOrderByReference: {
    isLoading: boolean,
    error: boolean,
    data: {
      id: string;
      created: string;
      updated: string;
      client: IClient;
      currencyCode: string;
      refNumber: string;
      accountReceivable: boolean;
      numberOfInstallments: string;
      nextTransaction: INextTransaction;
      lastPayment: ILastPayment;
      pendingAmount: number;
      totalAmount: number;
      status: string;
      paymentOrderDetails: [];
    },
  },
}

const paymentByOrderState: IPaymentByOrderState = {
  paymentOrderByReference: {
    isLoading: false,
    error: false,
    data: null,
  },
};

export type PaymentByOrderState = typeof paymentByOrderState;

const GET_PAYMENT_ORDER_BY_REFERENCE = {
  [`${ACTIONS.GET_PAYMENT_ORDER_BY_REFERENCE}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
    ...state,
    paymentOrderByReference: {
      isLoading: true,
      error: false,
      data: undefined,
    },
  }),
  [`${ACTIONS.GET_PAYMENT_ORDER_BY_REFERENCE}${ACTION_SUFFIX._FULFILLED}`]: (
    state: any,
    action: { payload: any }
  ) => {
    return {
      ...state,
      paymentOrderByReference: {
        isLoading: false,
        error: false,
        data: action.payload,
      },
    };
  },
  [`${ACTIONS.GET_PAYMENT_ORDER_BY_REFERENCE}${ACTION_SUFFIX._REJECTED}`]: (
    state: any,
    action: { payload: any }
  ) => {
    return {
      ...state,
      paymentOrderByReference: {
        isLoading: false,
        error: true,
        data: action.payload,
      },
    };
  }
};

let actions = {};

actions = { ...actions, ...GET_PAYMENT_ORDER_BY_REFERENCE };

export default handleActions(actions, paymentByOrderState);