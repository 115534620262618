import { handleActions } from 'redux-actions';
import { ACTIONS } from './actions';
import { MESSAGE_TYPE } from 'src/common/consts';

interface IMessagesState {
    messages: {
        show: boolean,
        type: MESSAGE_TYPE,
        message: string | JSX.Element,
    },
}

const messagesState: IMessagesState = {
    messages: {
        show: false,
        type: null,
        message: null,
    },
};

export type MessagesState = typeof messagesState;

const SET_MESSAGES = {
    [`${ACTIONS.SET_MESSAGES}`]: (state: any, action: { payload }) => ({
        ...state,
        messages: {
            show: action.payload.show,
            type: action.payload.type,
            message: action.payload.message,
        },
    }),
};

let actions = {};

actions = { ...actions, ...SET_MESSAGES };

export default handleActions(actions, messagesState);