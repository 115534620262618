import CreditNotesLang from "./CreditNotes";
import ItemDetailsLang from "./ItemDetails";
import {IPaymentoOrderInfoLang} from "../../types/payment-order-info/IPaymentOrderInfo"
import InstallmentsLang from "./InstallmentsLang";

const PaymentOrderInfoLang: IPaymentoOrderInfoLang = {
    created: 'Fecha de emisión',
    dueDate: 'Fecha de vencimiento',
    orderType: 'Tipo de Orden',
    expired: (days,expired,paid) => (
        <>
            {
                !paid ?
                    expired ? 
                    (<span> Vencida hace {days} días </span>)
                    :
                    (<span> Vence en {days} días </span>)
                :
                (<span> Pagada </span>)
            }
        </>
    ),
    merchant: {
        businessName: 'Razón social del emisor',
    },
    client: {
        commercialBusinessName: 'Razón social del receptor',
        taxId: 'RFC del receptor'
    },
    totalAmount: 'Orden total',
    paymentMethods: 'Medios de pago',
    creditNotes: CreditNotesLang,
    itemDetails: ItemDetailsLang,
    header: {
        payButton: 'Pagar'
    },
    installments: InstallmentsLang,
    taxFolio: 'Folio fiscal',
}

export default PaymentOrderInfoLang;