import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

const NotFound = lazy(() => import("src/modules/notFound/views"));
const CallbackCardAuthorization = lazy(() => import("src/modules/callbackCardAuthorization/views"));
const HomePaymentOrderDetail = lazy(() => import("src/modules/paymentOrderDetail/views"));
const HomeClientsPortal = lazy(() => import("src/modules/clientPortal/views/homeClientsPortal"));
const HomeApprovalPortal = lazy(() => import("src/modules/approvalPortal/components/ApprovalPortal"));
const HomePaymentByOrder = lazy(() => import("src/modules/hostedCheckout/views"));
const HomeOnboardingByClient = lazy(() => import("src/modules/onboarding/views/byClient"));
const HomeOnboardingByMerchant = lazy(() => import("src/modules/onboarding/views/byMerchant"));

const AppRouter = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <>...</>
        }
      >
        <Switch>
          <Route exact path="/notfound" component={NotFound} />
          <Route exact path="/client/:clientReference/orders" component={HomeClientsPortal} />
          <Route exact path="/approver/:approverReference/approvals" component={HomeApprovalPortal} />
          <Route exact path="/client/:clientReference/onboarding" component={HomeOnboardingByClient} />
          <Route exact path="/merchant/:merchantReference/onboarding" component={HomeOnboardingByMerchant} />
          <Route exact path="/transactions/:transactionReference" component={HomePaymentOrderDetail} />
          <Route exact path="/:orderReference" component={HomePaymentByOrder} />
          <Route path="/CC/callback/authorization/:authorization" component={CallbackCardAuthorization} />
          <Redirect to="/notfound" />
        </Switch>
      </Suspense>

    </Router>
  );
};

export default AppRouter;