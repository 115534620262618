import { GET_PO_BY_REFERENCE } from './queries';
import { getGraphqlClient } from '@graphql';

export const getPaymentOrderByReference = async (orderReference: string) => {
    const client = getGraphqlClient();
    const query = GET_PO_BY_REFERENCE;
    try {
        return await client
            .query({
                query,
                variables: { orderReference },
            })
            .then((res) => res.data.paymentOrderByReference);
    } catch (err) {
        return err;
    }
};