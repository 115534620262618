import { ACTION_SUFFIX } from '@redux/interfaces';
import { ACTIONS } from "../actions";

export const DISPUTE_TRANSACTION_PAYMENT = {
    [`${ACTIONS.DISPUTE_TRANSACTION_PAYMENT}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
        ...state,
        disputeTransactionPayment: {
            ...state.disputeTransactionPayment,
            isLoading: true,
            error: null,
            data: null,
        }
    }),
    [`${ACTIONS.DISPUTE_TRANSACTION_PAYMENT}${ACTION_SUFFIX._FULFILLED}`]: (state: any, action: { payload }) => {
        if (!!action.payload.graphQLErrors)
            return {
                ...state,
                disputeTransactionPayment: {
                    ...state.disputeTransactionPayment,
                    isLoading: false,
                    error: action.payload,
                }
            };
        return {
            ...state,
            disputeTransactionPayment: {
                ...state.disputeTransactionPayment,
                isLoading: false,
                data: action.payload
            }
        };
    },
    [`${ACTIONS.DISPUTE_TRANSACTION_PAYMENT}${ACTION_SUFFIX._REJECTED}`]: (state, action: { payload: any }) => ({
        ...state,
        disputeTransactionPayment: {
            ...state.disputeTransactionPayment,
            isLoading: false,
            error: action.payload
        }
    })
}