
import language from "@language";

const { errorService } = language;
const DEFAULT_ERROR_CODE = 'UNEXPECTED_ERROR';    

const getMessageByCode = (code?: string) => 
    (!!errorService[code] ? errorService[code] : errorService[DEFAULT_ERROR_CODE]);

export const mapperErrorRequestByCode = (code) => ({
    hasError: true,
    code,
    message: getMessageByCode(code)
});