import { getPOInfoByTransactionReference, getPaymentOrderInfoMock } from "./paymentOrderInfo";
import { getPaymentOrderByReference } from "./paymentByOrder";

const paymentOrderInfo = {
    getPOInfoByTransactionReference,
    getPaymentOrderInfoMock
}

const paymentByOrder = {
    getPaymentOrderByReference
}

export { paymentOrderInfo, paymentByOrder };