import { combineReducers } from 'redux';
import paymentOrderReducer, { PaymentOrderState } from 'src/PO-commons/store/paymentOrderInfo/reducer'
import creditCardAuthorizationReducer, { CreditCardAuthorizationState } from '../../modules/callbackCardAuthorization/store/creditCardAuthorization/reducer';
import clientReducer, { TClientState } from '../../common/store/client/reducers';
import poReducer, { TPOState } from '../../modules/clientPortal/store/paymentOrders/reducers';
import checkoutReducer, { TCheckoutState } from '../../common/store/checkout/reducer';
import summaryCardsReducer, { SummaryCardsState } from '../../modules/clientPortal/store/summaryCards/reducer';
import merchantReducer, { TMerchantState } from '../../common/store/merchant/reducers';
import messageReducer, { MessagesState } from '../../common/store/message/reducer';
import paymentByOrderReducer, { PaymentByOrderState } from 'src/PO-commons/store/paymentByOrder/reducer';


export const rootReducer = combineReducers({
  creditCardAuthorizationState: creditCardAuthorizationReducer,
  clientState: clientReducer,
  poState: poReducer,
  checkoutState: checkoutReducer,
  summaryCardsState: summaryCardsReducer,
  paymentOrderState: paymentOrderReducer,
  merchantState: merchantReducer,
  paymentByOrderState: paymentByOrderReducer,
  messages: messageReducer,
});

export interface State {
  creditCardAuthorizationState: CreditCardAuthorizationState;
  paymentOrderState: PaymentOrderState;
  clientState: TClientState;
  poState: TPOState;
  checkoutState: TCheckoutState;
  summaryCardsState: SummaryCardsState;
  merchantState: TMerchantState;
  paymentByOrderState: PaymentByOrderState;
  messages: MessagesState;
}
