import { createAction } from 'redux-actions';
import * as apiClient from '../../services';

export enum ACTIONS {
  GET_CLIENT_UID = 'CLIENT/GET_BY_UID',
  SET_CLIENT_REFERENCE = 'CLIENT/SET_CLIENT_REFERENCE',
  GET_CLIENT_PO = 'CLIENT/GET_BY_PO',
  SET_PO_REFERENCE = 'CLIENT/SET_PO_REFERENCE',
  PERSIST_CLIENT = 'CLIENT/SAVE',
  GET_CLIENT_FILTERS = 'CLIENT/GET_BY_FILTERS',
  ADD_FIRST_FILTER_TO_DATASET = 'CLIENT/ADD_FIRST_FILTER_TO_DATASET',
  GET_JOURNEY ='CLIENT/GET_JOURNEY',
  CREATE_JOURNEY = 'CLIENT/CREATE_JOURNEY',
  DOWNLOAD_BALANCE = 'CLIENT/DOWNLOAD_BALANCE',
}

export const getClientByUID = createAction(ACTIONS.GET_CLIENT_UID, apiClient.client.byReference);
export const setClientReference = ACTIONS.SET_CLIENT_REFERENCE;

export const getClientByPO = createAction(ACTIONS.GET_CLIENT_PO, apiClient.client.byPaymentOrderReference);
export const setPORerefence = ACTIONS.SET_PO_REFERENCE;

export const persistClient = createAction(ACTIONS.PERSIST_CLIENT, apiClient.client.persistClient);

export const getByFilters = createAction(ACTIONS.GET_CLIENT_FILTERS, apiClient.client.byFilters);
export const addFistFilteredToDataset = ACTIONS.ADD_FIRST_FILTER_TO_DATASET;

export const getJourney = createAction(ACTIONS.GET_JOURNEY, apiClient.client.getJourney)
export const createJourney = createAction(ACTIONS.CREATE_JOURNEY, apiClient.client.createJourney)

export const downloadClientBalanceAction = createAction(ACTIONS.DOWNLOAD_BALANCE, apiClient.client.downloadClientBalance)
