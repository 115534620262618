import NumberFormat from 'react-number-format';
import { CURRENCIES, PRICE_SYMBOL } from 'src/common/consts';

export interface IAmount {
  value: number;
  display?: 'text' | 'input';
  isNegative?: boolean;
  className?: string;
  isForeignCurrency?: boolean;
}

const Amount = ({ isForeignCurrency, value, className, display, isNegative }: IAmount) => {
  const prefix = isForeignCurrency ? CURRENCIES.USD : CURRENCIES.MXN;
  return (
    <span className={isNegative && ' text-danger'}>
      {isNegative && '-'}
      <NumberFormat
        value={value}
        className={className}
        displayType={display ? display : 'text'}
        thousandSeparator={','}
        decimalSeparator={'.'}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={`${prefix} ${PRICE_SYMBOL}`}
      />
    </span>
  );
};

export default Amount;
