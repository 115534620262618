import { getGraphqlClient } from '../graphql';
import { mapperClient, mapperClientByFilters, mapperClientByPO } from './mapper';
import { GET_CLIENT_BY_FILTERS, GET_CLIENT_BY_PO, GET_CLIENT_UID, PERSIST_CLIENT,GET_JOURNEY,CREATE_JOURNEY, DOWNLOAD_BALANCE } from './graphql';
import { IClientFilters, IClientInput } from 'src/common/types';

export const clientByReference = async (reference: string) => {
  const client = getGraphqlClient();
  const query = GET_CLIENT_UID;
  try {
    return await client
      .query({
        query,
        variables: { reference },
      })
      .then((res) => {
        return mapperClient(res);
      });
  } catch (err) {
    return err;
  }
};

export const clientByPaymentOrderReference = async (paymentOrderReference: string) => {
  const client = getGraphqlClient();
  const query = GET_CLIENT_BY_PO;
  try {
    return await client
      .query({
        query,
        variables: { paymentOrderReference },
      })
      .then((res) => {
        return mapperClientByPO(res);
      });
  } catch (err) {
    return err;
  }
};

export const persistClient = async (clientInput: IClientInput): Promise<any> => {
  const graphqlClient = getGraphqlClient();
  const mutation = PERSIST_CLIENT;
  const variables = {
    clientInput: { ...clientInput }
  };

  return await graphqlClient
    .mutate({
      mutation,
      variables
    })
    .then((res) => {
      return res.data.persistClient;
    })
    .catch((err) => {
      return err;
    });
};

export const clientByFilters = async (
  merchantId: number,
  filters: IClientFilters,
  page: number = 0,
  size: number = 20) => {
  const client = getGraphqlClient();
  const query = GET_CLIENT_BY_FILTERS;
  return await client
    .query({
      query,
      variables: { merchantId, filters, page, size }
    })
    .then((res) => {
      return mapperClientByFilters(res);
    });
};

export const getJourney = async (
  taxId: string,
  merchantReference: string,
) => {
  const client = getGraphqlClient();
  const query = GET_JOURNEY;
  return await client
   .query({
      query,
      variables: { taxId, merchantReference }
    })
   .then((res) => {
      return res.data.onboardingJourney;
    });
}

export const createJourney = async (
  clientReference: string
) => {
  const client = getGraphqlClient();
  const mutation = CREATE_JOURNEY;
  return await client
   .mutate({
      mutation,
      variables: { clientReference }
    })
   .then((res) => {
      return res.data.createOnboarding;
    });
}

export const downloadClientBalance = async (
  clientReference: string
) => {
  const client = getGraphqlClient();
  const mutation = DOWNLOAD_BALANCE;
  return await client
   .mutate({
      mutation,
      variables: { clientReference }
    })
   .then((res) => {
      return res.data.accountStatementPDF;
    });
}
