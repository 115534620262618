import { IOrderCompleted } from "../types/IOrderCompleted";

const OrderCompleted: IOrderCompleted = {
    title: '¡Pago exitoso!',
    paymentDetails: 'Detalles del pago',
    paragraphHeader: (refNumber, paymentMethod, currencyCode, totalAmount) => (
        <>
            Pagaste la orden de pago {refNumber} con{' '}
            {paymentMethod} por{' '}{currencyCode}{' '}${totalAmount}.
        </>
    ),
    paymentMethods: {
        CREDIT_CARD: 'Tarjeta de crédito',
        BANK_TRANSFER: 'Transferencia Bancaria',
        CASH: 'Efectivo',
        CREDIT: 'Crédito',
    },
    total: 'Total',
    paymentDate: 'Fecha de pago',
}

export default OrderCompleted;  