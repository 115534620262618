import { ACTION_SUFFIX } from '@redux/interfaces';
import { ACTIONS } from '../actions';

export const GET_CLIENT_FILTERS =
{
    [`${ACTIONS.GET_CLIENT_FILTERS}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
        ...state,
        filtered: {
            isLoading: true,
            error: null,
            data: null
        }
    }),
    [`${ACTIONS.GET_CLIENT_FILTERS}${ACTION_SUFFIX._FULFILLED}`]: (state: any, action: { payload }) => {
        if (!!action.payload.graphQLErrors)
            return {
                ...state,
                filtered: {
                    isLoading: false,
                    error: action.payload,
                    data: null
                }
            };
        return {
            ...state,
            filtered: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [`${ACTIONS.GET_CLIENT_FILTERS}${ACTION_SUFFIX._REJECTED}`]: (state, action: { payload: any }) => ({
        ...state,
        filtered: {
            isLoading: false,
            error: action.payload,
            data: null
        }
    })
};

export const GET_JOURNEY = {
    [`${ACTIONS.GET_JOURNEY}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
        ...state,
        onboarding: {
            isLoading: true,
            error: null,
            data: null
        }
    }),
    [`${ACTIONS.GET_JOURNEY}${ACTION_SUFFIX._FULFILLED}`]: (state: any, action: { payload }) => {
        if (!!action.payload?.graphQLErrors)
            return {
                ...state,
                onboarding: {
                    isLoading: false,
                    error: action.payload,
                    data: null
                }
            };
        return {
            ...state,
            onboarding: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [`${ACTIONS.GET_JOURNEY}${ACTION_SUFFIX._REJECTED}`]: (state, action: { payload: any }) => ({
        ...state,
        onboarding: {
            isLoading: false,
            error: action.payload,
            data: null
        }
    })
};

export const CREATE_JOURNEY = {
    [`${ACTIONS.CREATE_JOURNEY}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
        ...state,
        onboarding: {
            isLoading: true,
            error: null,
            data: null
        }
    }),
    [`${ACTIONS.CREATE_JOURNEY}${ACTION_SUFFIX._FULFILLED}`]: (state: any, action: { payload }) => {
        if (!!action.payload?.graphQLErrors)
            return {
                ...state,
                onboarding: {
                    isLoading: false,
                    error: action.payload,
                    data: null
                }
            };
        return {
            ...state,
            onboarding: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [`${ACTIONS.CREATE_JOURNEY}${ACTION_SUFFIX._REJECTED}`]: (state, action: { payload: any }) => ({
        ...state,
        onboarding: {
            isLoading: false,
            error: action.payload,
            data: null
        }
    })
};

export const ADD_FIRST_FILTER_TO_DATASET = {
    [ACTIONS.ADD_FIRST_FILTER_TO_DATASET]: (state) => {
        const filtered = !!state.filtered.data ? state.filtered.data.list : [];
        if (!filtered.length)
            return { ...state }

        return {
            ...state,
            data: { ...filtered[0] }
        }
    }
}