const API = {
  Host: process.env.REACT_APP_KALTO_API_HOST,
  Port: process.env.REACT_APP_KALTO_API_PORT
};

const port = API.Port ? (':' + API.Port) : '';
const kaltoPath = `${API.Host}${port}/kalto`;

export const kaltoEndpoints = {
  MIDDLEWARE: `${kaltoPath}/graphql`,
  OPEN_MIDDLEWARE: `${kaltoPath}/open-graphql`
};