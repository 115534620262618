import { ACTION_SUFFIX } from '@redux/interfaces';
import { ACTIONS } from '../actions';

export const PERSIST_CLIENT =
{
    [`${ACTIONS.PERSIST_CLIENT}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
        ...state,
        persist: {
            isLoading: true,
            error: null
        }
    }),
    [`${ACTIONS.PERSIST_CLIENT}${ACTION_SUFFIX._FULFILLED}`]: (state: any, action: { payload }) => {
        if (!!action.payload.graphQLErrors)
            return {
                ...state,
                persist: {
                    isLoading: false,
                    error: action.payload,
                    data: null
                }
            };
        return {
            ...state,
            persist: {
                isLoading: false,
                error: null,
                data: action.payload
            },
            data: action.payload
        };
    },
    [`${ACTIONS.PERSIST_CLIENT}${ACTION_SUFFIX._REJECTED}`]: (state, action: { payload: any }) => ({
        ...state,
        persist: {
            isLoading: false,
            error: null
        }
    })
};