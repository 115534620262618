import { createAction } from 'redux-actions';
import * as apiClient from '../../services';

export enum ACTIONS {
  GET_BY_TRANSACTION_REFERENCE = 'PAYMENT_ORDER/GET_BY_TRANSACTION_REFERENCE',
  SET_LATE_FEE_TOTAL_AMOUNT_TRANSACTION = 'SET_LATE_FEE_TOTAL_AMOUNT_TRANSACTION',
}

export const getByTransactionReference = createAction(ACTIONS.GET_BY_TRANSACTION_REFERENCE, apiClient.paymentOrderInfo.getPOInfoByTransactionReference);
export const setLateFeeTotalAmountByTransaction = createAction(ACTIONS.SET_LATE_FEE_TOTAL_AMOUNT_TRANSACTION);
