import { handleActions } from "redux-actions";
import { ACTION_SUFFIX } from '@redux/interfaces';
import { ACTIONS } from './actions';
import { ISummaryCards } from "../../types";

const summaryCardsState: ISummaryCards = {
    expiredCard: {
        execute: () => null,
        isLoading: false,
        error: false,
        data: {
            expiredAmount: 0,
            expiredDaysQty: 0,
            expiredQty: 0
        }
    },
    pendingCard: {
        execute: () => null,
        isLoading: false,
        error: false,
        data: {
            pendingAmount: 0,
            pendingDaysQty: 0,
            pendingQty: 0
        }
    },
    creditLineCard: {
        execute: () => null,
        isLoading: false,
        error: false,
        data: {
            creditLineAmount: 0,
            isCreditPaused: false,
            totalCredit: 0,
            status: null,
            showCard: true,
            overdueAmount: 0,
        }
    },
    upcomingPaymentCard: {
        execute: () => null,
        isLoading: false,
        error: false,
        data: {
            orderReference: null,
            upcomingPaymentAmount: 0,
            upcomingPaymentQty: 0
        }
    }
};

export type SummaryCardsState = typeof summaryCardsState;

const GET_PENDING_CARD = {
    [`${ACTIONS.GET_PENDING_CARD}${ACTION_SUFFIX._PENDING}`]: (
        state: SummaryCardsState
    ) => {
        return {
            ...state,
            pendingCard: {
                ...state.pendingCard,
                isLoading: true,
            }
        }
    },
    [`${ACTIONS.GET_PENDING_CARD}${ACTION_SUFFIX._FULFILLED}`]: (
        state: SummaryCardsState,
        action: { payload: any }
    ) => {
        return {
            ...state,
            pendingCard: {
                isLoading: false,
                error: false,
                data: action.payload
            }
        }
    },
    [`${ACTIONS.GET_PENDING_CARD}${ACTION_SUFFIX._REJECTED}`]: (
        state: SummaryCardsState,
        action: { payload: any },
    ) => {
        return {
            ...state,
            pendingCard: {
                isLoading: false,
                error: true,
                data: action.payload
            },
        };
    },
};

const GET_EXPIRED_CARD = {
    [`${ACTIONS.GET_EXPIRED_CARD}${ACTION_SUFFIX._PENDING}`]: (
        state: SummaryCardsState
    ) => {
        return {
            ...state,
            expiredCard: {
                ...state.expiredCard,
                isLoading: true,
            }
        }
    },
    [`${ACTIONS.GET_EXPIRED_CARD}${ACTION_SUFFIX._FULFILLED}`]: (
        state: SummaryCardsState,
        action: { payload: any }
    ) => {
        return {
            ...state,
            expiredCard: {
                isLoading: false,
                error: false,
                data: action.payload
            }
        }
    },
    [`${ACTIONS.GET_EXPIRED_CARD}${ACTION_SUFFIX._REJECTED}`]: (
        state: SummaryCardsState,
        action: { payload: any },
    ) => {
        return {
            ...state,
            expiredCard: {
                isLoading: false,
                error: true,
                data: action.payload
            },
        };
    },
};

const GET_UPCOMING_PAYMENT_CARD = {
    [`${ACTIONS.GET_UPCOMING_PAYMENT_CARD}${ACTION_SUFFIX._PENDING}`]: (
        state: SummaryCardsState
    ) => {
        return {
            ...state,
            upcomingPaymentCard: {
                ...state.upcomingPaymentCard,
                isLoading: true,
            }
        }
    },
    [`${ACTIONS.GET_UPCOMING_PAYMENT_CARD}${ACTION_SUFFIX._FULFILLED}`]: (
        state: SummaryCardsState,
        action: { payload: any }
    ) => {
        return {
            ...state,
            upcomingPaymentCard: {
                isLoading: false,
                error: false,
                data: action.payload
            }
        }
    },
    [`${ACTIONS.GET_UPCOMING_PAYMENT_CARD}${ACTION_SUFFIX._REJECTED}`]: (
        state: SummaryCardsState,
        action: { payload: any },
    ) => {
        return {
            ...state,
            upcomingPaymentCard: {
                isLoading: false,
                error: true,
                data: action.payload
            },
        };
    },
};

const GET_CREDIT_LINE_CARD = {
    [`${ACTIONS.GET_CREDIT_LINE_CARD}${ACTION_SUFFIX._PENDING}`]: (
        state: SummaryCardsState
    ) => {
        return {
            ...state,
            creditLineCard: {
                ...state.creditLineCard,
                isLoading: true,
            }
        }
    },
    [`${ACTIONS.GET_CREDIT_LINE_CARD}${ACTION_SUFFIX._FULFILLED}`]: (
        state: SummaryCardsState,
        action: { payload: any }
    ) => {
        return {
            ...state,
            creditLineCard: {
                isLoading: false,
                error: false,
                data: action.payload
            }
        }
    },
    [`${ACTIONS.GET_CREDIT_LINE_CARD}${ACTION_SUFFIX._REJECTED}`]: (
        state: SummaryCardsState,
        action: { payload: any },
    ) => {
        return {
            ...state,
            creditLineCard: {
                isLoading: false,
                error: true,
                data: action.payload
            },
        };
    },
};

let actions = {};

actions = { ...actions, ...GET_PENDING_CARD };
actions = { ...actions, ...GET_EXPIRED_CARD };
actions = { ...actions, ...GET_UPCOMING_PAYMENT_CARD };
actions = { ...actions, ...GET_CREDIT_LINE_CARD };

export default handleActions(actions, summaryCardsState);