import { ACTION_SUFFIX } from '@redux/interfaces';
import { ACTIONS } from '../actions';

export const GET_CLIENT_UID =
{
    [`${ACTIONS.GET_CLIENT_UID}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
        ...state,
        isLoading: true,
        error: null,
        client: null,
    }),
    [`${ACTIONS.GET_CLIENT_UID}${ACTION_SUFFIX._FULFILLED}`]: (state: any, action: { payload }) => {
        if (!!action.payload.graphQLErrors)
            return {
                ...state,
                isLoading: false,
                data: null,
                error: action.payload,
            };
        return {
            ...state,
            isLoading: false,
            data: action.payload,
            error: null
        };
    },
    [`${ACTIONS.GET_CLIENT_UID}${ACTION_SUFFIX._REJECTED}`]: (state, action: { payload: any }) => ({
        ...state,
        isLoading: false,
        data: null,
        error: action.payload
    })
};

export const SET_CLIENT_REFERENCE = {
    [ACTIONS.SET_CLIENT_REFERENCE]: (
        state,
        action: { payload }
    ) => {
        const { uid } = action.payload;
        return {
            ...state,
            clientReference: uid
        }
    }
}