const days = (date1, date2) => {
    let difference = date1.getTime() - date2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
}

export const mapperExpiredPayments = (expiredPayments: any) => {
    const { amount, dueDate, transactionReferences } = expiredPayments;
    const expiredPaymentsFormatted = {
        expiredQty: transactionReferences.length,
        expiredAmount: amount,
        expiredDaysQty: days(new Date(), new Date(dueDate))
    };
    return expiredPaymentsFormatted;
};

export const mapperPendingPayments = (pendingPayments: any) => {
    const { amount, dueDate, transactionReferences } = pendingPayments;
    const pendingPaymentsFormatted = {
        pendingQty: transactionReferences.length,
        pendingAmount: amount,
        pendingDaysQty: days(new Date(dueDate), new Date())
    };
    return pendingPaymentsFormatted;
};

export const mapperUpcomingPayments = (upcomingPayments: any) => {
    const { amount, transactionReferences, orderReference } = upcomingPayments;
    const upcomingPaymentsFormatted = {
        upcomingPaymentQty: transactionReferences.length,
        upcomingPaymentAmount: amount,
        orderReference
    };
    return upcomingPaymentsFormatted;
};