import { getGraphqlClient } from '../graphql';
import { GET_MERCHANT } from './graphql';
import { mapperMerchant } from './mapper';

export const merchantByReference = async (reference: string) => {
  const graphqlClient = getGraphqlClient();
  const query = GET_MERCHANT;
  try {
    return await graphqlClient
      .query({
        query,
        variables: { reference },
      })
      .then((res) => {
        return mapperMerchant(res);
      });
  } catch (err) {
    return err;
  }
};