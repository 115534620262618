import { handleActions } from 'redux-actions';
import { ClientPortalPaymentsInProgress, ClientPortalPaymentsInProgressPage, ClientPortalTransaction, ClientPortalTransactionPage } from 'src/modules/clientPortal/types';
import { GET_BY_HISTORY } from './history';
import { GET_BY_TOBE_PAY, SELECT_ORDER_TOBE_PAY, UNSELECT_ALL } from './toBePay';
import { GET_BY_PAYMENTS_IN_PROGRESS } from './paymentsInProgress';
import { DISPUTE_TRANSACTION_PAYMENT } from './disputeTransactionPayment';

interface IState {
  toBePay?: {
    isLoading?: boolean;
    data?: ClientPortalTransactionPage
    error?: any;
    totalAmount?: number;
    selected?: Array<ClientPortalTransaction>;
  },
  history?: {
    isLoading?: boolean;
    data?: ClientPortalTransactionPage;
    error?: any;
    selected?: Array<ClientPortalTransaction>;
  },
  paymentsInProgress?: {
    isLoading?: boolean;
    data?: ClientPortalPaymentsInProgressPage;
    error?: any;
    selected?: Array<ClientPortalPaymentsInProgress>;
  },
  disputeTransactionPayment?: {
    isLoading?: boolean;
    data?: any;
    error?: any;
  },
}

const poState: IState = {
  toBePay: {
    isLoading: false,
    data: null,
    error: null,
    totalAmount: 0,
    selected: []
  },
  history: {
    isLoading: false,
    data: null,
    error: null
  },
  paymentsInProgress: {
    isLoading: false,
    data: null,
    error: null
  },
  disputeTransactionPayment: {
    isLoading: false,
    data: null,
    error: null
  }
};

export type TPOState = typeof poState;

let actions = {};
actions = { ...actions, ...GET_BY_TOBE_PAY };
actions = { ...actions, ...GET_BY_HISTORY };
actions = { ...actions, ...GET_BY_PAYMENTS_IN_PROGRESS };
actions = { ...actions, ...SELECT_ORDER_TOBE_PAY };
actions = { ...actions, ...UNSELECT_ALL };
actions = { ...actions, ...DISPUTE_TRANSACTION_PAYMENT };

export default handleActions(actions, poState);