import INotfound from "../types/INotfound";

const NotFound: INotfound = {
    message: 'Oops! No encontramos datos disponibles',
    contactSupport: {
        message: 'Si crees que esto es un error, por favor comunícate con nosotros',
        linkLabel: 'Contáctanos',
        email: 'support@kalto.com',
        subject: 'Orden no encontrada',
    },
}

export default NotFound;