import Amount from "@common-components/amount";
import { IFinancingCard } from "../types/IFinancingCard";

export const FinancingCard: IFinancingCard = {
    declinedElementText: () => <>Seguiremos <strong>monitoreando tu información</strong> para avisarte cuando tengas una línea pre-aprobada.</>,
    declinedElementTitle: 'Línea de crédito rechazada',
    inReviewElementText: () => <>Seguimos analizando tu solicitud. Recibirás una <strong>notificación a tu correo</strong> electrónico <strong>con la respuesta.</strong></>,
    inReviewElementTitle: 'Línea de crédito en proceso',
    pendingElementText: () => <>Aplica a una <strong>línea de crédito</strong> en 3 pasos y <strong>paga tus órdenes</strong> haciendo uso de ella.</>,
    pendingTextBtn: 'Aplicar',
    blockedElementTitle: 'Línea de crédito bloqueada',
    blockedElementText: 'No puedes seguir aplazando tus órdenes de pago, por favor contáctanos para solucionarlo.',
    blockedTextBtn: 'Aplicar',
    expiredElementTitle: 'Línea de crédito vencida',
    expiredElementText: (amount) => <>Paga tu deuda de <strong><Amount value={amount} /></strong> para seguir aplazando tus órdenes de pago.</>,
    expiredTextBtn: 'Pagar deuda',
    downloadBalance: 'Estado de cuenta'
};