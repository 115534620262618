import { handleActions } from 'redux-actions';
import { ACTION_SUFFIX } from '@redux/interfaces';
import { ACTIONS } from './actions';

interface IState {
  isLoading: boolean;
  error: any;
  paymentOrderInfo: any,
  lateFeeTotalAmount: number
}

const paymentOrderState: IState = {
  isLoading: false,
  error: null,
  paymentOrderInfo: null,
  lateFeeTotalAmount: 0
};

export type PaymentOrderState = typeof paymentOrderState;

const GET_BY_TRANSACTION_REFERENCE = {
  [`${ACTIONS.GET_BY_TRANSACTION_REFERENCE}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
    ...state,
    isLoading: true,
    error: null,
    paymentOrderInfo: null,
  }),
  [`${ACTIONS.GET_BY_TRANSACTION_REFERENCE}${ACTION_SUFFIX._FULFILLED}`]: (state: any, action: { payload }) => {
    if (!!action.payload.graphQLErrors)
      return {
        ...state,
        isLoading: false,
        paymentOrderInfo: null,
        error: action.payload,
      };
    return {
      ...state,
      isLoading: false,
      paymentOrderInfo: action.payload,
    };
  },
};

const SET_LATE_FEE_TOTAL_AMOUNT_TRANSACTION = {
  [ACTIONS.SET_LATE_FEE_TOTAL_AMOUNT_TRANSACTION]: (state: any, action: { payload }) => {
    return {
      ...state,
      lateFeeTotalAmount: action.payload
    };
  }
};

let actions = {};

actions = { ...actions, ...GET_BY_TRANSACTION_REFERENCE };
actions = { ...actions, ...SET_LATE_FEE_TOTAL_AMOUNT_TRANSACTION };

export default handleActions(actions, paymentOrderState);
