import { handleActions } from 'redux-actions';
import { IClient } from 'src/common/types';
import { GET_CLIENT_UID, SET_CLIENT_REFERENCE } from './byClient';
import { ADD_FIRST_FILTER_TO_DATASET, GET_CLIENT_FILTERS, GET_JOURNEY, CREATE_JOURNEY } from './byFilters';
import { GET_CLIENT_BY_PO, SET_PO_REFERENCE } from './byPO';
import { PERSIST_CLIENT } from './saveClient';

interface IClientSet {
  data: IClient;
  isLoading: boolean;
  error: any;
}

interface ICLientListSet {
  data: {
    list: IClient[];
    size?: number;
    totalPages?: number;
    totalElements?: number;
    number?: number;
  }
  isLoading: boolean;
  error: any;
}

interface IOnboardingData {
  status: string;
  onboardingLink: string;
  gatewayName: string;
}

interface IClientOnboarding {
  data: IOnboardingData;
  isLoading: boolean;
  error: any;
}

interface IState {
  isLoading: boolean;
  error: any;
  data: IClient;
  clientReference?: string;
  POReference?: string;
  persist: IClientSet;
  filtered: ICLientListSet;
  onboarding: IClientOnboarding;
}

const clientState: IState = {
  isLoading: false,
  error: null,
  data: null,
  clientReference: null,
  POReference: null,
  persist: {
    data: null,
    isLoading: false,
    error: null
  },
  filtered: {
    data: null,
    isLoading: false,
    error: null
  },
  onboarding: {
    data: null,
    isLoading: false,
    error: null
  }
};

export type TClientState = typeof clientState;

let actions = {};
actions = { ...actions, ...GET_CLIENT_UID };
actions = { ...actions, ...SET_CLIENT_REFERENCE };
actions = { ...actions, ...SET_PO_REFERENCE };
actions = { ...actions, ...GET_CLIENT_BY_PO };
actions = { ...actions, ...PERSIST_CLIENT };
actions = { ...actions, ...GET_CLIENT_FILTERS };
actions = { ...actions, ...ADD_FIRST_FILTER_TO_DATASET };
actions = { ...actions, ...GET_JOURNEY };
actions = { ...actions, ...CREATE_JOURNEY };

export default handleActions(actions, clientState);