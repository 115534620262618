import { gql } from '@apollo/client';

export const GET_BY_TRANSACTION_REFERENCE = gql`
  query clientPortalPaymentOrderByTransaction ( $filters: ClientPortalTransactionReferenceFilters! ) {
    clientPortalPaymentOrderByTransaction(filters: $filters) {
      reference
      refNumber
      created
      dueDate
      daysToDue
      fiscalNumber
      paymentOrderType
      currency
      totalAmount
      paymentDate
      status
      expired
      creditNotesAmount
      pendingAmount
      installments {
            sequence
            totalAmount
            pendingAmount
            paidAmount
            status
            reference
            paymentDate
            dueDate
      }
      merchant {
        businessName
        reference
      }
      client {
        commercialBusinessName
        taxId
      }
      invoice {
        invoiceTerm {
          dueDate
          shippingDate
        }
      }
      paymentMethods
      itemDetails {
        itemName
        quantity
        unitPrice
        amount
      }
      creditNotes {
        description
        amount
      }
      notes
    }
  }
`;