import { IReceipt } from '@client-portal/hooks/useFetchPaymentComplements';
import { getGraphqlClient } from '../graphql';
import { GET_PAYMENT_ORDER_INVOICE } from './graphql';
import { TypeFormat } from 'src/common/types';

export const downloadPaymentOrderInvoice = async (reference: string, invoiceFormat: TypeFormat): Promise<IReceipt> => {
  const graphqlClient = getGraphqlClient();
  const query = GET_PAYMENT_ORDER_INVOICE;
  try {
    const response = await graphqlClient.query({ query, variables: { reference, invoiceFormat }, });
    return response?.data.fetchPaymentOrderInvoiceReceipt;
  } catch (err) {
    console.error(`Error trying to download receipt in format ${invoiceFormat}`, err);
    return null;
  }
};