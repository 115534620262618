import { gql } from '@apollo/client';

export const GET_CREDIT_CARD_AUTHORIZATION = gql`
query ($authorizationReference: String!, $chargeId: String!) {
  creditCardAuthorization(authorizationReference: $authorizationReference, chargeId: $chargeId) {
    status
    paymentRedirectUrl
    collectedAmount
    paymentMethodAuthorization {
      paymentMethod
      authorizationError
    } 
  }
}
`;