import { getGraphqlClient } from '@graphql';
import { mapperErrorRequestByCode } from '@common-services/serviceErrorMapper';
import { getErrorMessage } from './getErrorMessage';
import { GET_CREDIT_CARD_AUTHORIZATION } from './queries';
import { CREDIT_CARD_AUTHORIZATION_STATUS } from '../../const';

export const authorization = async (authorizationReference: string, chargeId: string) => {
  const graphqlClient = getGraphqlClient();
  const query = GET_CREDIT_CARD_AUTHORIZATION;

  return await graphqlClient
    .query({
      query,
      variables: {
        authorizationReference,
        chargeId
      },
    })
    .then((res) => {
      const creditCardAuthorization = res.data.creditCardAuthorization

      return !!creditCardAuthorization
        ? (creditCardAuthorization.status === CREDIT_CARD_AUTHORIZATION_STATUS.COLLECTED)
          ? creditCardAuthorization
          : mapperErrorRequestByCode(creditCardAuthorization.paymentMethodAuthorization.authorizationError)
        : getErrorMessage(res.errors);
    })
    .catch((error) => getErrorMessage(error.graphQLErrors));
};