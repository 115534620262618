import { mapperGetPObyTransactionReference } from './mapper';
import { GET_BY_TRANSACTION_REFERENCE } from './graphql';
import { getGraphqlClient } from '@graphql';
import { IPaymentOrderDetail, PaymentOrderInfoFilter } from '../../types';

export const getPOInfoByTransactionReference = async (transactionReference: string) => {
    const client = getGraphqlClient();
    const query = GET_BY_TRANSACTION_REFERENCE;
    const filters: PaymentOrderInfoFilter = {
        transactionReference: transactionReference
    }

    try {
        return await client
            .query({
                query,
                variables: { filters: { ...filters } },
            })
            .then((res) => {
                return mapperGetPObyTransactionReference(res.data);
            });
    } catch (err) {
        return err;
    }
}

export const getPaymentOrderInfoMock = async (params: string) => {
    const paymentOrderInfo: IPaymentOrderDetail = {
        "clientPortalPaymentOrderByTransaction": {
            "id": "-5106534569952410475",
            "reference": "bb185599457575ccfc3ad3c3",
            "refNumber": "c0f57ca1-a1fa-45da-872a-b0b670b7a890",
            "created": "2022-09-09T19:00:23Z",
            "dueDate": "2022-09-19T20:00:06Z",
            "daysToDue": 10,
            "paymentOrderType": "INSTALLMENT",
            "currency": "MXN",
            "fiscalNumber": "150",
            "totalAmount": 100.0,
            "paymentDate": null,
            "status": "SUBMITTED",
            "expired": false,
            "creditNotesAmount": 20.0,
            "pendingAmount": 50.0,
            "merchant": {
                "businessName": "zupply",
                "reference": "test"
            },
            "client": {
                "commercialBusinessName": "ClientCashInitialized",
                "taxId": "9999999999"
            },
            "invoice": null,
            "installments": [
                {
                    "totalAmount": 100.0,
                    "pendingAmount": 50.0,
                    "paidAmount": 0.0,
                    "status": "SUBMITTED",
                    "paymentDate": null,
                    "sequence": 1,
                    "dueDate": null,
                    "reference": ''
                },
                {
                    "totalAmount": 100.0,
                    "pendingAmount": 50.0,
                    "paidAmount": 0.0,
                    "status": "SUBMITTED",
                    "paymentDate": null,
                    "sequence": 2,
                    "dueDate": null,
                    "reference": ''

                }
            ],
            "paymentMethods": [
                "CASH",
                "BANK_TRANSFER",
                "CREDIT_CARD"
            ],
            "itemDetails": [
                {
                    "itemName": "Laptop dell, color negro, 16 GB",
                    "quantity": 10,
                    "amount": 100.0
                },
                {
                    "itemName": "Laptop dell, color negro, 16 GB",
                    "quantity": 10,
                    "amount": 100.0
                }
            ],
            "creditNotes": [
                {
                    "merchantReferenceId": "Croca-nc-30",
                    "description": "Some description 2",
                    "amount": 10.0
                },
                {
                    "merchantReferenceId": "Croca-nc-58",
                    "description": "Some description 5",
                    "amount": 10.0
                }
            ],
            "notes": "23456-sdcasfg-CSS250708SE6",
            "lateFeeTotalAmount": 150
        }
    }

    return mapperGetPObyTransactionReference(paymentOrderInfo);
};