import { gql } from '@apollo/client';

export const GET_PO_BY_REFERENCE = gql`
  query paymentOrderByReference ( $orderReference: String! ) {
    paymentOrderByReference(orderReference: $orderReference) {
      id
      created
      updated
      client {
        reference
        commercialBusinessName
        merchant {
          businessName
          reference
        }
        termsQualificationStatus
        creditLine {
          id
          amount
          currency
          status
          balance {
            availableAmount
          }
        }
        onboardingLink {
          link
          kaltoLink
        }
        paymentMethods {
          enabled
          paymentMethod {
            name
          }
        }
      }
      currencyCode
      refNumber
      accountReceivable
      creditNotesAmount
      numberOfInstallments
      nextTransaction {
        reference
        sequenceNumber
        dueDate
        status
        totalAmount
        paidAmount
        expired
      }
      lastPayment {
        date
        currency
        amount
      }
      pendingAmount
      totalAmount
      status
      paymentOrderDetails {
        itemName
        quantity
        unitPrice
        amount
      }
      type {
        name
      } 
    }
  }
`;