import { handleActions } from 'redux-actions';
import { IMerchant } from 'src/common/types';
import { GET_MERCHANT_REFERENCE, SET_MERCHANT_REFERENCE } from './byMerchant';

interface IState {
  isLoading: boolean;
  error: any;
  data: IMerchant;
  merchantReference?: string;
}

const merchantState: IState = {
  isLoading: false,
  error: null,
  data: null,
  merchantReference: null
};

export type TMerchantState = typeof merchantState;

let actions = {};
actions = { ...actions, ...GET_MERCHANT_REFERENCE };
actions = { ...actions, ...SET_MERCHANT_REFERENCE };

export default handleActions(actions, merchantState);