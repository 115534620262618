import { IClient } from "src/common/types";

export const mapperClient = (serviceData: any): IClient => {
  return serviceData.data.clientByReference;
}

export const mapperClientByPO = (serviceData: any): IClient => {
  return serviceData.data.clientByPaymentOrderReference;
}

export const mapperClientByFilters = (serviceData: any): IClient => {
  return serviceData.data.clients;
}