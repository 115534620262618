import { ISummaryCard } from "../types/ISummaryCard";

const SummaryCard: ISummaryCard = {
    expiredCardTitle: (expiredQty) => `${expiredQty} PAGOS VENCIDOS`,
    pendingCardTitle: (pendingQty: number) => {
        return (pendingQty > 0 ? `${pendingQty}` : 'NO HAY') + ' PAGOS PENDIENTES';
    },
    creditLineCardTitle: 'CŔEDITO DISPONIBLE',
    upcomingPaymentTitle: 'PRÓXIMO PAGO',
    infoBadgeContent: 'En pausa',
    pendingFooterTitle: 'TOTAL DE CUENTAS POR PAGAR',
    expiredFooter: (value) => `Más de ${value} días de vencimiento.`,
    pendingFooter: (value) => `Próximo a vencer en ${value} días.`,
    upcomingPaymentFooter: (qty, orderReference: string) => {
        if (!orderReference) return 'No hay órden de pago';
        return qty > 1 ? `${qty} ÒRDENES PROXIMAS A VENCER` : `Órden de pago ${orderReference}`;
    },
    errorMsg: '¡Oops! Tuvimos un error al cargar la info, inténtalo de nuevo.',
    btnRefresh: 'Refrescar'
};

export default SummaryCard;