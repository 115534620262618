import { gql } from "@apollo/client";

export const CLIENT_INSIGHTS_EXPIRED_PAYMENTS = gql`
    query ClientInsightsExpiredPayments($clientReference: String!) {
        clientInsightsExpiredPayments(clientReference: $clientReference) {
            currency
            amount
            dueDate
            transactionReferences
        }
    }
`;

export const CLIENT_INSIGHTS_PENDING_PAYMENTS = gql`
    query ClientInsightsPendingPayments($clientReference: String!) {
        clientInsightsPendingPayments(clientReference: $clientReference) {
            currency
            amount
            dueDate
            transactionReferences
        }
    }
`;

export const CLIENT_INSIGHTS_UPCOMING_PAYMENTS = gql`
    query ClientInsightsUpcomingPayments($clientReference: String!) {
        clientInsightsUpcomingPayments(clientReference: $clientReference) {
            currency
            amount
            dueDate
            transactionReferences
            orderReference
        }
    }
`;