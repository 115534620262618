import { IGeneral } from "../types/IGeneral";

const General: IGeneral = {
    acceptButton: 'Aceptar',
    apiErrorMessage:
        'Lo sentimos, se ha producido un error al intentar procesar su consulta. Por favor, verifique que los datos ingresados sean correctos y vuelva a intentar en unos minutos.',
    loading: 'Procesando...',
    download: 'Descargar',
    copyright: '© Kalto Tech, S.A. de C.V.',
    termsAndConditions: 'Términos y condiciones',
    privacyPolicy: 'Aviso de privacidad',
    responsabilityPolicy: {
        title: 'Aviso de responsabilidad',
        text: () => (
            <>
                <div>
                    “Kalto Tech, S.A. de C.V. realiza el servicio de cobro de cuentas por cobrar de sus comitentes,
                    por cuenta y orden de estos. Por lo que, Kalto utiliza a terceros prestadores de servicios y
                    procesadores de diversos métodos de pago. El pago de cuentas por pagar por parte de Usted (Pagador)
                    no genera relación jurídica alguna con Kalto.
                </div>
                <div className='mt-3'>
                    Kalto recibe los importes pagados por Usted al comitente de Kalto a nombre y por cuenta de éste,
                    para cancelación de las obligaciones que Usted tiene con dicho comitente”.
                </div>
            </>
        ),
        closeButton: 'Cerrar y continuar'
    },
    linkTermsAndConditions:
        'https://drive.google.com/file/d/10rpWEeDOVa5QgVB_xoscFmT1jpCj6ivr/view',
    linkPrivacyPolicy: 'https://drive.google.com/file/d/19gvJOM7MBTVBBuWws8jcSvzBxGAm-EsZ/view',
    inProgress: 'Procesando...',
    kalto: 'kalto',
    paymentOrderType: {
        installment: 'Cuotas',
        invoice: 'Factura'
    }
};

export default General;