import { createAction } from 'redux-actions';
import {
    getCreditLineCardDataByClient,
    getExpiredCardDataByClient,
    getPendingCardDataByClient,
    getUpcomingPaymentsCardDataByClient
} from 'src/modules/clientPortal/services/summaryCard';

export enum ACTIONS {
    GET_PENDING_CARD = 'GET_PENDING_CARD',
    GET_EXPIRED_CARD = 'GET_EXPIRED_CARD',
    GET_UPCOMING_PAYMENT_CARD = 'GET_UPCOMING_PAYMENT_CARD',
    GET_CREDIT_LINE_CARD = 'GET_CREDIT_LINE_CARD'
};

export const getPendingCard = createAction(ACTIONS.GET_PENDING_CARD, getPendingCardDataByClient);
export const getExpiredCard = createAction(ACTIONS.GET_EXPIRED_CARD, getExpiredCardDataByClient);
export const getUpcomingPaymentCard = createAction(ACTIONS.GET_UPCOMING_PAYMENT_CARD, getUpcomingPaymentsCardDataByClient);
export const getCreditLineCard = createAction(ACTIONS.GET_CREDIT_LINE_CARD, getCreditLineCardDataByClient);

