import { Language } from '../types/ILang';
import CreditCardAuthorization from './CreditCardAuthorization';
import { EmptyTable } from './EmptyTable';
import ErrorService from './ErrorService';
import { FinancingCard } from './FinancingCard';
import General from './General';
import PaymentByOrderLang from './payment-by-order/paymentByOrderLang';
import PaymentOrderStatusBadgeLang from './payment-by-order/paymentOrderStatusBadgeLang';
import NotFound from './NotFound';
import OrderCancelled from './OrderCancelled';
import OrderCompleted from './OrderCompleted';
import PaymentOrderInfoLang from './payment-order-detail/PaymentOrderInfo';
import PaymentSummary from './PaymentSummary';
import POGrid from './POGrid';
import SummaryCard from './SummaryCard';
import ApprovalPortal from './approvalPortal/ApprovalPortal';

export default {
  general: General,
  notFound: NotFound,
  orderCompleted: OrderCompleted,
  orderCancelled: OrderCancelled,
  creditCardAuthorization: CreditCardAuthorization,
  errorService: ErrorService,
  summaryCard: SummaryCard,
  emptyTable: EmptyTable,
  poGrid: POGrid,
  paymentSummary: PaymentSummary,
  paymentOrderInfoLang: PaymentOrderInfoLang,
  paymentByOrderLang: PaymentByOrderLang,
  paymentOrderStatusBadgeLang: PaymentOrderStatusBadgeLang,
  financingCard: FinancingCard,
  approvalPortal: ApprovalPortal,
} as unknown as Language;
