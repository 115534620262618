import { ACTION_SUFFIX } from '@redux/interfaces';
import { ACTIONS } from "../actions";

export const GET_BY_HISTORY = {
    [`${ACTIONS.GET_BY_HISTORY}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
        ...state,
        history: {
            ...state.history,
            isLoading: true,
            error: null,
            data: null,
        }
    }),
    [`${ACTIONS.GET_BY_HISTORY}${ACTION_SUFFIX._FULFILLED}`]: (state: any, action: { payload }) => {
        if (!!action.payload.graphQLErrors)
            return {
                ...state,
                history: {
                    ...state.history,
                    isLoading: false,
                    error: action.payload,
                }
            };
        return {
            ...state,
            history: {
                ...state.history,
                isLoading: false,
                data: action.payload
            }
        };
    },
    [`${ACTIONS.GET_BY_HISTORY}${ACTION_SUFFIX._REJECTED}`]: (state, action: { payload: any }) => ({
        ...state,
        history: {
            ...state.history,
            isLoading: false,
            error: action.payload
        }
    })
}