import { handleActions } from 'redux-actions';
import { ACTION_SUFFIX } from '@redux/interfaces';
import { ACTIONS } from './actions';
import { IPaymentMethodAuthorization } from '../../types';

interface IState {
  isLoading: boolean;
  error: any;
  status: string;
  collectedAmount: number;
  paymentRedirectUrl: string;
  paymentMethodAuthorization: IPaymentMethodAuthorization[]
}

const creditCardAuthorizationState: IState = {
  isLoading: true,
  error: null,
  status: null,
  collectedAmount: null,
  paymentRedirectUrl: null,
  paymentMethodAuthorization: [],
};

export type CreditCardAuthorizationState = typeof creditCardAuthorizationState;

export default handleActions(
  {
    [`${ACTIONS.CREDIT_CARD_AUTHORIZATION}${ACTION_SUFFIX._PENDING}`]: (state: any) => ({
      ...state,
      isLoading: true,
      error: null,
      status: null,
      collectedAmount: null,
      paymentRedirectUrl: null,
      paymentMethodAuthorization: [],
    }),
    [`${ACTIONS.CREDIT_CARD_AUTHORIZATION}${ACTION_SUFFIX._FULFILLED}`]: (state: any, action: { payload }) => {
      if (!!action.payload.graphQLErrors || !!action.payload.hasError)
        return {
          ...state,
          isLoading: false,
          error: action.payload,
          status: null,
          collectedAmount: null,
          paymentRedirectUrl: null,
          paymentMethodAuthorization: [],
        };
      return {
        ...state,
        isLoading: false,
        error: null,
        status: action.payload.status,
        collectedAmount: action.payload.collectedAmount,
        paymentRedirectUrl: action.payload.paymentRedirectUrl,
        paymentMethodAuthorization: action.payload.paymentMethodAuthorization,
      };
    },
    [`${ACTIONS.CREDIT_CARD_AUTHORIZATION}${ACTION_SUFFIX._REJECTED}`]: (state, action: { payload: any }) => ({
      ...state,
      isLoading: false,
      error: action.payload,
      status: null,
      collectedAmount: null,
      paymentRedirectUrl: null,
      paymentMethodAuthorization: [],
    }),
  },
  creditCardAuthorizationState
);
